<template>
  <div class="admin-menu-items">
    <div class="admin-menu-items-content">
      <div
        :class="`admin-menu-item ${mkLink('adminStatus=new&type=story', 'on')}`"
        @click="goFilter({ adminStatus: 'new', type: 'story', view: _view })"
      >
        <span>{{ $locale["words"]["pending"] }}</span>
        <span class="itemBubble" v-if="storiesByReview">{{ storiesByReview }}</span>
      </div>
      <div
        :class="`admin-menu-item ${mkLink('adminStatus=approved&type=story', 'on')}`"
        @click="goFilter({ adminStatus: 'approved', type: 'story', view: _view })"
      >
        <span>{{ $locale["words"]["approved"] }}</span>
      </div>
      <div
        :class="`admin-menu-item ${mkLink('adminStatus=disapproved&type=story', 'on')}`"
        @click="goFilter({ adminStatus: 'disapproved', type: 'story', view: _view })"
      >
        <span>{{ $locale["words"]["disapproved"] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["_view"],
  methods: {
    goFilter: function(filter, path) {
      const currentPath = path ? path : this.$creatorFeed ? this.$creatorFeed.user : "/";
      this.$router.push({ path: currentPath, query: filter }).catch(() => {});
      this.$global.scrollToEl({ el: ".main-feed", topgap: 60 });
      this.$emit("filter");
    },
  },
  computed: {
    isType: function() {
      if (this.$route.query.adminStatus && this.$route.query.type !== "story") {
        return "posts";
      } else if (this.$route.query.adminStatus && this.$route.query.type === "story") {
        return "stories";
      }
    },
    storiesByReview: function() {
      return this.$store.state.counter.storiesByReview;
    },
  },
};
</script>
